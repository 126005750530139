@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
  @import url('tldraw/tldraw.css');

  html {
    font-family: 'Pretendard', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #171719;
  }

  .no-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /*익스플로러 기본 화살표 없애기*/
  select::-ms-expand {
    display: none;
  }
  /*화살표 기본 css 없애기*/
  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

@layer components {
  .button-login {
    @apply w-[802px] px-[160px] py-[32px] rounded-[20px] flex justify-center select-none cursor-pointer;

    background: linear-gradient(
      117deg,
      rgba(128, 128, 128, 0.27) 10.2%,
      rgba(186, 186, 186, 0.27) 100.38%
    );

    backdrop-filter: blur(15px);
  }

  .div-classroomList_left {
    @apply px-[63px] py-[48px] rounded-l-[40px] overflow-auto no-scroll;

    background: linear-gradient(
      117deg,
      rgba(190, 197, 220, 0.27) 10.2%,
      rgba(112, 114, 123, 0.27) 100.38%
    );

    backdrop-filter: blur(15px);
  }

  .div-classroom {
    @apply py-24 mb-[30px] cursor-pointer select-none px-28;

    border-radius: 20px;

    background: linear-gradient(
      117deg,
      rgba(128, 128, 128, 0.27) 10.2%,
      rgba(186, 186, 186, 0.27) 100.38%
    );

    backdrop-filter: blur(15px);
  }

  .div-classroomList_right {
    @apply px-32 py-48 rounded-r-[40px];

    background: linear-gradient(152deg, rgba(9, 9, 9, 0.59)-3.86%, rgba(23, 41, 67, 0.59) 107.73%);
  }
}
